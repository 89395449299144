<template>
  <div>
    公司首頁
  </div>
</template>

<script>

export default {
}
</script>
